import { ClientDatabroker } from "@itm21st/databroker";
import config from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import { useClientDatabroker } from "@itm21st/databroker-react";

const baseURL = config.databrokerApiUrl;

const useDatabroker = (): ClientDatabroker => {
    const { getAccessTokenSilently } = useAuth0();
    const databroker = useClientDatabroker({
        baseURL,
        getRequestHeaders: async () => {
            const token = await getAccessTokenSilently();
            return { Authorization: `Bearer ${token}` };
        }
    });
    return databroker;
};

export default useDatabroker;
